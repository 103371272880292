import type { SourceFilter } from '@uipath/portal-shell/loader';

import type { IDirectoryEntry } from '../../common/interfaces/cis/directory';
import { DirectoryEntityType } from '../../common/interfaces/cis/directory';
import {
    get,
    post,
} from '../utility/Requests';

const userPartitionUrl = `${process.buildConfigs.identityApiBaseRoute}/Directory`;

export function getDirectoryEntities(
    {
        url: _url, partitionGlobalId, sourceFilter, startsWith,
    }: {
        url: string;
        partitionGlobalId: string;
        sourceFilter: SourceFilter[];
        startsWith: string;
    }) {
    return get<IDirectoryEntry[]>(`${userPartitionUrl}/Search/${partitionGlobalId}`, {
        urlParams: {
            sourceFilter,
            startsWith,
        },
    });
}

export function resolveByName(
    {
        url: _url, partitionGlobalId, entityName, entityType,
    }: {
        url: string;
        partitionGlobalId: string;
        entityName: string;
        entityType: DirectoryEntityType;
    }) {
    return post<IDirectoryEntry>(`${userPartitionUrl}/ResolveByName/${partitionGlobalId}`, {
        body: {
            entityName,
            entityType: entityType === DirectoryEntityType.user ? 'user' : 'group',
        },
    });
}

export function resolveById(
    {
        url: _url, partitionGlobalId, entityId, entityType, groupIds,
    }: {
        url?: string;
        partitionGlobalId: string;
        entityId: string;
        entityType: DirectoryEntityType;
        groupIds?: string[];
    }) {
    return post<IDirectoryEntry>(`${userPartitionUrl}/ResolveById/${partitionGlobalId}`, {
        body: {
            entityId,
            entityType: entityType === DirectoryEntityType.user ? 'user' : 'group',
            groupIds,
        },
    });
}
